/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button, } from 'reactstrap';

import { _, Http, API, Utils } from 'Helpers';

class ExposureDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posting: false,
            exposures: [],
            isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
        }
    }

    componentDidMount() {
        const { LDO_fancy } = this.props;
        this.setState({ LDO_fancy }, () => {
            this.getExposureDetails()
        })
    }

    getExposureDetails = () => {
        let param = { odd_id: this.props.odd_id, child_session_type_id: this.props.child_session_type_id, event_id: this.props.event_id }
        this.setState({ posting: true })
        Http.post(this.state.LDO_fancy ? API.LDO_EXPOSURE_DATA : API.EXPOSUREDETAILS, param).then(response => {
            this.setState({
                posting: false,
                exposures: response.data
            })
        }).catch(error => {
            this.setState({ posting: false })
        });
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { exposures, isThemeGradient } = this.state;
        const ModalProps = {
            isOpen,
            toggle,
            className: 'custom-modal2'
        }

        return (
            <Modal {...ModalProps} className="exposure_details">
                <i className="close-btn icon-cancel" onClick={toggle} />
                <ModalHeader className={isThemeGradient ? "bg-gradient-3" : ""}>Run Position</ModalHeader>
                <ModalBody>
                    <table className="table table-bordered">
                        <thead >
                            <tr className={isThemeGradient ? "bg-radial-1" : ""}>
                                <th>Run</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        {
                            exposures != "" &&
                            (
                                <tbody>
                                    {
                                        _.map(exposures, (item, index) => {
                                            return (
                                                <tr key={index} className={item.net_pl < 0 ? "lena" : "dena"}>
                                                    <td>{item.range}</td>
                                                    <td>{item.net_pl}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            )
                        }
                    </table>
                </ModalBody>
                <div className="row justify-content-center mx-0">
                    <div className="col-auto px-3 py-2">
                        <Button color="primary" size="sm" onClick={() => toggle()}>
                            OK
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

ExposureDetailsModal.defaultProps = {
    ExposureProps: {},
    isOpen: true,
    toggle: () => { }
}

export default ExposureDetailsModal;
