import React, { Component } from 'react'
import { Container, Table, Row, Col, Button } from 'reactstrap';
import { Http, Utils, _, API } from "Helpers";
import { Fragment } from 'react';
import { TitleComponent } from 'Components';

class Ledgers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source_id: this.props.location.state.LedgerDetails.source_id,
      ledgerData: [],
      colspan: 6,
      casinoBetDetails: [],
    }
    this.getLedgerDetails();
  }

  redirect = () => {
    this.props.history.push({
      pathname: "/ledgers"
    })
  }

  getLedgerDetails = () => {
    let param = {
      event_id: this.state.source_id
    }
    this.setState({ posting: true })
    Http.post(API.LEDGERDETAILS, param).then(response => {
      this.setState({
        posting: false,
        ledgerData: response.data
      }, () => {
        if (response.data.detail && !_.isEmpty(response.data.detail) && response.data.detail.sports_id && !_.isEmpty(response.data.detail.sports_id) && Number(response.data.detail.sports_id) === 6)
          this.getCasinoBetDetails();
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  getCasinoBetDetails = () => {
    let param = {
      event_id: this.state.source_id
    }
    this.setState({ posting: true })
    Http.post(API.CASINO_BET_DETAIL, param).then(response => {
      if (response.data[0] && !_.isEmpty(response.data[0]) && response.data[0].BetList && !_.isEmpty(response.data[0].BetList))
        this.setState({ casinoBetDetails: response.data[0].BetList })

      this.setState({ posting: false })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { ledgerData, casinoBetDetails } = this.state;
    return (
      <Container className="mb-5">
        <TitleComponent title={this.props.location.state.LedgerDetails.detail} />
        <Row>
          <Col sm="12" md={{ size: 10, offset: 1 }} className="led_del_tab" >
            {
              _.map(ledgerData.mdata, (item, index) => {
                let colspan = 4;
                _.map(item.bs, (ditem, dindex) => {
                  return (
                    dindex == 0 ?
                      ditem.market_runners !== null ?
                        _.map(JSON.parse(ditem.market_runners), (ritm, rid) => {
                          return (
                            colspan = Number(colspan) + 1
                          )
                        }) : ''
                      : ''
                  )
                })
                return (
                  <Fragment key={index}>
                    <Table striped bordered responsive className="mx-0 my-2" key={index}>
                      <thead>
                        <tr key={"head-" + index}>
                          <th colSpan={colspan}>{item.market_name}</th>
                        </tr>
                        <tr key={"col-" + index}>
                          <th>RATE</th>
                          <th>AMOUNT</th>
                          <th>MODE</th>
                          <th>Team</th>
                          {
                            _.map(item.bs, (ditem, dindex) => {
                              return (
                                dindex == 0 &&
                                <Fragment key={dindex}>
                                  {
                                    ditem.market_runners !== null &&
                                    _.map(JSON.parse(ditem.market_runners), (ritm, rid) => {
                                      return (
                                        <th key={rid}>{ritm.runnerName}</th>
                                      )
                                    })
                                  }
                                </Fragment>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          _.map(item.bs, (mitem, mindex) => {
                            return (
                              <tr key={mindex}>
                                <td>{mitem.price}</td>
                                <td>{mitem.stake}</td>
                                <td>{Number(mitem.bookmaker_type) !== 3 ? (mitem.betting_type == 1 ? "Lagai" : "Khai") : (mitem.betting_type == 1 ? "No" : "Yes")}</td>
                                <td>
                                  {
                                    mitem.market_runners !== null ?
                                      _.map(JSON.parse(mitem.market_runners), (ritm, rid) => {
                                        return (
                                          mitem.selection_id == ritm.selectionId ? ritm.runnerName : ''
                                        )
                                      }) : mitem.market_name
                                  }
                                </td>
                                {
                                  mitem.market_runners !== null &&
                                  _.map(JSON.parse(mitem.market_runners), (ritm, rid) => {
                                    return (
                                      <Fragment key={rid}>
                                        {
                                          _.map(JSON.parse(mitem.runner_pl), (ritem, rindex) => {
                                            return (
                                              rindex == ritm.selectionId &&
                                              <td key={rindex}>{Utils.TwoDecimalVal(ritem)}</td>
                                            )
                                          })
                                        }
                                      </Fragment>
                                    )
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    <Row>
                      <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-3">
                        <Box><span className={Number(item.profit_loss) < 0 ? "loss" : "won"}>{Number(item.profit_loss) < 0 ? "You Lost " + Math.abs(Number(item.profit_loss)) + " Coins." : "You won " + Math.abs(Number(item.profit_loss)) + " Coins."}</span></Box>
                      </Col>
                    </Row>
                  </Fragment>
                )
              })
            }
          </Col>
        </Row>
        {
          ledgerData.fbs && !_.isEmpty(ledgerData.fbs) &&
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }} className="led_del_tab" >
              <Table striped bordered responsive className="mx-0 my-2">
                <thead>
                  <tr>
                    <th colSpan="5">Session Bets</th>
                  </tr>
                  <tr>
                    <th>SESSION</th>
                    <th>RUNS</th>
                    <th>RATE</th>
                    <th>AMOUNT</th>
                    <th>MODE</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(ledgerData.fbs, (item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.market_name}</td>
                          <td>{item?.child_session_type_id == 10 ? "1" : item.line}</td>
                          <td>{Utils.TwoDecimalVal(Number(item.price) - 1)}</td>
                          <td>{item.stake}</td>
                          <td>
                            {(Number(item?.child_session_type_id) === 10)
                              ? (item.betting_type == 2 ? "EVEN" : "ODD")
                              : (item.betting_type == 2 ? "No" : "Yes")}
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        }
        {
          casinoBetDetails && !_.isEmpty(casinoBetDetails) && (Number(ledgerData.detail.sports_id) === 6) &&
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }} className="led_del_tab" >
              <Table striped bordered responsive className="mx-0 my-2">
                <thead>
                  <tr>
                    <th colSpan="9">Session Bets</th>
                  </tr>
                  <tr>
                    <th>Ref ID</th>
                    <th>M Date</th>
                    <th>Runner</th>
                    <th>Odds</th>
                    <th>Stake</th>
                    <th>PIL</th>
                    <th>Dr</th>
                    <th>Cr</th>
                    <th>Net</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(casinoBetDetails, (item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.BetID}</td>
                          <td>{Utils.dateTimeFormat(item.CreatedDate, 'DD/MM/YYYY, h:mm A')}</td>
                          <td>{item.Runner}</td>
                          <td>{Utils.TwoDecimalVal(item.Rate)}</td>
                          <td>{Utils.TwoDecimalVal(item.Stake)}</td>
                          <td>{Utils.TwoDecimalVal(item.PL)}</td>
                          <td>{Utils.TwoDecimalVal(item.Dr)}</td>
                          <td>{Utils.TwoDecimalVal(item.Cr)}</td>
                          <td>{Utils.TwoDecimalVal(item.Net)}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        }
        {
          ledgerData !== null ?
            <Fragment>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-3">
                  <Box1>Match Plus Minus</Box1>
                  <Box><span className={Number(ledgerData.detail?.match_pm) < 0 ? "loss" : "won"}>{Number(ledgerData.detail?.match_pm) < 0 ? "You Lost " + Math.abs(Number(ledgerData.detail?.match_pm)) + " Coins." : "You won " + Math.abs(Number(ledgerData.detail?.match_pm)) + " Coins."}</span></Box>
                </Col>
              </Row>
              {
                ledgerData.fbs !== null &&
                <Row>
                  <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-3">
                    <Box1>Session Plus Minus</Box1>
                    <Box><span className={Number(ledgerData.detail?.session_pm) < 0 ? "loss" : "won"}>{Number(ledgerData.detail?.session_pm) < 0 ? "You Lost " + Math.abs(Number(ledgerData.detail?.session_pm)) + " Coins." : "You won " + Math.abs(Number(ledgerData.detail?.session_pm)) + " Coins."}</span></Box>
                  </Col>
                </Row>
              }
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-4">
                  <Box1>My Commission</Box1>
                  <Box2>{(Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))} Coins.</Box2>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-4">
                  <Box1>Amount Afer Comm. & Share</Box1>
                  <Box><span className={Number(ledgerData.detail?.profit_loss) < 0 ? "loss" : "won"}>{ledgerData.detail?.profit_loss < 0 ? "You Lost " + Math.abs(Number(ledgerData.detail?.profit_loss) + (Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))) + " Coins." : "You won " + Math.abs(Number(ledgerData.detail?.profit_loss) + (Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))) + " Coins."}</span></Box>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-4">
                  <Box1>Winning Commission</Box1>
                  <Box><span className="loss">You Lost {Math.abs(Number(ledgerData.detail?.winning_commission))} Coins.</span></Box>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-4">
                  <Box1>Mob. App. Charges</Box1>
                  <Box><span className="loss">You Lost {Math.abs(Number(ledgerData.detail?.other_charges))} Coins.</span></Box>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-4">
                  <Box1>Net. Plus Minus</Box1>
                  <Box><span className={((Number(ledgerData.detail?.profit_loss) + (Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))) - Number(ledgerData.detail?.other_charges) - Number(ledgerData.detail?.winning_commission)) < 0 ? "loss" : "won"}>{((Number(ledgerData.detail?.profit_loss) + (Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))) - Number(ledgerData.detail?.other_charges) - Number(ledgerData.detail?.winning_commission)) < 0 ? "You Lost " + Math.abs((Number(ledgerData.detail?.profit_loss) + (Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))) - Number(ledgerData.detail?.other_charges) - Number(ledgerData.detail?.winning_commission)) + " Coins." : "You won " + Math.abs((Number(ledgerData.detail?.profit_loss) + (Number(ledgerData.detail?.match_commission) + Number(ledgerData.detail?.session_commission))) - Number(ledgerData.detail?.other_charges) - Number(ledgerData.detail?.winning_commission)) + " Coins."}</span></Box>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 2 }} className="mt-4">
                  <Button onClick={() => { this.redirect(); }} className="b1_btl" color="$primary"><a>Back To List</a></Button>
                </Col>
              </Row>
            </Fragment>
            : ""
        }
      </Container>
    )
  }
}

export default Ledgers;
const Box = props => <div className="box">{props.children} </div>;
const Box1 = props => <div className="box1">{props.children} </div>;
const Box2 = props => <div className="box2">{props.children} </div>;
